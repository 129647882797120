import { useState } from "react";
import { Abi } from 'abitype';
import type { TransactionReceipt } from "viem";
import { useChainId, usePublicClient, useWalletClient } from "wagmi";
import { toast } from "react-toastify";

export type Args = Array<any>;

export type Web3ButtonContext = {
  address: `0x${string}`;
  abi: Abi;
  readContract: (functionName: string, args: Args) => Promise<any>;
  readContractCustom: (contractAddress: `0x${string}`, contractAbi: Abi, functionName: string, args: Args) => Promise<any>;
  estimateAndSend: (functionName: string, args: Args, overrides?: any) => Promise<`0x${string}`>;
  estimateAndSendCustom: (contractAddress: `0x${string}`, contractAbi: Abi, functionName: string, args: Args, overrides?: any) => Promise<`0x${string}`>;
};

export type Web3ButtonProps = {
  contractAddress: `0x${string}`;
  contractAbi: Abi;
  action: (ctx: Web3ButtonContext) => Promise<undefined | `0x${string}`>;
  onError: (err: unknown) => Promise<void>;
  onSuccess: (r: TransactionReceipt) => Promise<void>;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

export function Web3Button(
  { contractAddress, contractAbi, action, onError, onSuccess, children, className = undefined, disabled = undefined }: Web3ButtonProps
) {
  const [isActing, setIsActing] = useState(false);
  const publicClient = usePublicClient();
  const { /*isSuccess,*/ /*isLoading: signerIsLoading,*/ data: walletClient } = useWalletClient();
  // const { openChainModal } = useChainModal();
  const chain = useChainId();
  const [isConfirming, setIsConfirming] = useState(false);

  const goodChain = !!chain || true;

  async function estimateAndSend(
    address: `0x${string}`,
    abi: Abi,
    functionName: string,
    args: Args,
    overrides: any
  ): Promise<`0x${string}`> {
    if (!walletClient)
      throw new Error(`Estimation and sending needs wallet connection`);
    let params = {
      account: walletClient.account.address,
      address,
      abi,
      functionName,
      args,
      gas: 0n,
    };
    if (overrides)
      params = Object.assign(params, overrides);
    params.gas = await publicClient!.estimateContractGas(params);
    return await walletClient!.writeContract(params);
  }


  async function onClick(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    ev.preventDefault();
    ev.stopPropagation();
    if (isActing)
      return;
    if (!publicClient || !walletClient)
      return;
    // if (!isSuccess) {
    //   initConnection();
    //   return;
    // }
    // if (!goodChain) {
    //   openChainModal();
    //   return;
    // }
    setIsActing(true);
    try {
      const ctx: Web3ButtonContext = {
        address: contractAddress,
        abi: contractAbi,
        readContract: async function (functionName: string, args: Args) {
          return publicClient!.readContract({
            address: contractAddress,
            abi: contractAbi,
            functionName, args
          });
        },
        readContractCustom: async function (contractAddress: `0x${string}`, contractAbi: Abi, functionName: string, args: Args) {
          return publicClient!.readContract({
            address: contractAddress,
            abi: contractAbi,
            functionName, args
          });
        },
        estimateAndSend: async function (functionName: string, args: Args, overrides: any = undefined) {
          return estimateAndSend(
            contractAddress, contractAbi,
            functionName, args, overrides
          );
        },
        estimateAndSendCustom: async function (contractAddress: `0x${string}`, contractAbi: Abi, functionName: string, args: Args, overrides: any = undefined) {
          return estimateAndSend(
            contractAddress, contractAbi,
            functionName, args, overrides
          );
        },
      };
      const txHash = await action(ctx);
      setIsActing(false);
      if (txHash) {
        setIsConfirming(true);
        const promise = publicClient.waitForTransactionReceipt({ hash: txHash });
        toast.promise(promise, { pending: `Waiting for transaction confirmation` });
        const txResult: TransactionReceipt = await promise;
        if (onSuccess)
          await onSuccess(txResult);
        setIsConfirming(false);
      }
      setIsActing(false);
    }
    catch (err: unknown) {
      setIsActing(false);
      if (onError)
        await onError(err);
      setIsConfirming(false);
    }
  }

  return <button
    className={`${className ? className + " " : ""}${(isActing || isConfirming) ? "bg-animate-barberpole " : ""}relative flex flex-row items-center gap-2 btn ${goodChain ? "" : "bg-red-600"}`}
    onClick={onClick}
    disabled={disabled}
  >
    {(isActing || isConfirming) &&
      <span className="animate-ping h-4 w-4 absolute -top-1 -right-1 inline-flex rounded-full bg-orange-400 opacity-100"></span>
    }
    {children}
  </button>;
}