import Modal from 'react-modal';
import { BrowserRouter, /*Link,*/ Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAccount, useBalance } from 'wagmi';
import Administration from './Administration';
import { Amount } from './Amount';
import './App.css';
import Home from './Home';
import Styles from "./Styles";
import { Web3ModalProvider } from './Web3ModalProvider';
import { useChainConfig } from './config';

Modal.setAppElement('#root');

function Balance() {
  const { chainConfig } = useChainConfig();
  const { address } = useAccount();
  const { data, isLoading } = useBalance({
    token: chainConfig.hoaAddress,
    address,
  });
  return <div className='text-lg text-center'>
      <Amount amount={data?.value} isLoading={isLoading} loadingClassName='w-16' /> HOA
  </div>;
}

function App() {
  return <>
    <Web3ModalProvider>
      <BrowserRouter>
        <header className='bg-header flex flex-row p-2 h-20'>
          <div className='flex-row-valign w-full text-xl'>
            <img alt="Logo" src="/favicon.ico" className="w-14 h-14" />
            <div className="font-3 text-4xl grow">HOAMI</div>
            {/* <div className='flex-row-valign grow justify-center gap-2'>
              <Link to="/">Home</Link>
              <Link to="/styles">Styles</Link>
            </div> */}
            <div className='flex flex-col justify-center'>
              <w3m-button balance='hide' />
              <Balance />
            </div>
          </div>
        </header>
        <main className='flex flex-row justify-center p-2'>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Administration />} />
            <Route path="/styles" element={<Styles />} />
          </Routes>
        </main>
      </BrowserRouter>
    </Web3ModalProvider>
    <ToastContainer position="bottom-right"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  </>;
}

export default App;




