import { useAccount } from 'wagmi';

export interface ChainConfig {
  readonly name: string,
  readonly gasCurrencySymbol: string,
  readonly gasCurrencyWrappedAddress: `0x${string}`,
  readonly gasCurrencyDisplayDecimals: number,
  readonly blockExplorer: string,
  readonly rpcUrl: string,
  readonly hoaAddress: `0x${string}`,
  readonly royaltyPoolAddress: `0x${string}`,
  readonly hoamiAddress: `0x${string}`,
  readonly piipAddress: `0x${string}`,
};

interface Config {
  readonly defaultChainId: 369,
  readonly supportedChainIds: Array<number>;
  readonly chainConfigs: {
    [key: number]: ChainConfig
  }
};

export const config: Config = {
  defaultChainId: 369,
  supportedChainIds: [
    369, // PulseChain Mainnet
    943, // PulseChain Testnet v4
  ],

  chainConfigs: {
    369: {
      name: 'PulseChain',
      gasCurrencySymbol: 'PLS',
      gasCurrencyWrappedAddress: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
      gasCurrencyDisplayDecimals: 2,
      blockExplorer: 'https://ipfs.pulsemarket.app/ipfs/QmTJ88Zu7vftEWTvhPvu7EjpjaN9dE13CBGAz7qEJov1WN/#',
      rpcUrl: 'https://rpc.pulsechain.com',
      hoaAddress: '0x7901a3569679AEc3501dbeC59399F327854a70fe',
      royaltyPoolAddress: "0x7928b63bCf0cdB80836596b48055DC26d16f1CDB", // FIXME
      hoamiAddress: "0xb62599F55993A2221C073De48F3a547D7E08E4AB", // FIXME
      piipAddress: "0x84F6c3df1dfF3c194f5F93f84Aec80114412BcA7", // FIXME
    },
    943: {
      name: 'PulseChain Testnet v4',
      gasCurrencySymbol: 'tPLS',
      gasCurrencyWrappedAddress: '0x70499adEBB11Efd915E3b69E700c331778628707',
      gasCurrencyDisplayDecimals: 2,
      rpcUrl: 'https://rpc.v4.testnet.pulsechain.com',
      blockExplorer: 'https://scan.v4.testnet.pulsechain.com',
      hoaAddress: '0xbc7A37f00212A1587C00ac9C01Da11BF715fBa2b',
      royaltyPoolAddress: "0x9ef91356b53003C252D69f69B99a530180B199f9",
      hoamiAddress: "0xC7ae582598C720A44F9D8a860061291F2f609132",
      piipAddress: "0xD7BD642C51D0E21942aE60998b848a88742bD590",
    },
  },
};

const chainConfigMissingErrorShowed = new Map<number, Date>();

export function useChainConfig() {
  const { chain } = useAccount();

  const chainId = chain?.id ?? config.defaultChainId;
  let chainConfig = config.chainConfigs[chainId];
  if (chainConfig === undefined) {
    if (!chainConfigMissingErrorShowed.has(chainId)) {
      console.error(`No configuration for chain ${chainId}`);
      chainConfigMissingErrorShowed.set(chainId, new Date());
    }
  }
  return {
    chainConfig,
    chain,
    chainId,
  };
}
