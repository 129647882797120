import { readContracts } from '@wagmi/core';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAccount, usePublicClient, useReadContracts } from 'wagmi';
import { Amount } from './Amount';
import { MintModal } from './MintModal';
import { Web3Button, Web3ButtonContext } from './Web3Button';
import { config } from './Web3ModalProvider';
import { hoamiAbi, piipAbi, royaltyPoolAbi } from './abis';
import { ChainConfig, useChainConfig } from './config';
import { erc20Abi } from 'viem';

type TokenMetadata = {
  decimals: number;
  symbol: string;
};

type TokenMetadataMap = Map<`0x${string}`, TokenMetadata>;

type RewardTokenInfo = {
  address: `0x${string}`;
  claimableAmount: bigint | undefined;
};

type NftInfo = {
  readonly tokenId: bigint;
  claimablePiip: bigint | undefined;
  rewardTokens: RewardTokenInfo[];
};

type NftPanelProps = {
  nft: NftInfo;
  chainConfig: ChainConfig;
  rewardTokenMetadata: TokenMetadataMap;
  onBurnt: () => void;
  onPiipClaimed: () => void;
}

function NftPanel({
  nft,
  chainConfig,
  rewardTokenMetadata,
  onBurnt,
  onPiipClaimed
}: NftPanelProps) {
  return <div className='panel flex flex-row w-full gap-2'>
    <div className='flex flex-col gap-2 justify-start flex-grow'>
      <div className='flex-row-valign'>
        <img alt="Logo" src="/logo192.png" className="w-12 h-12" />
        <div className='flex-col'>
          <div>HOAMI #{nft.tokenId.toString()}</div>
          <div>Claimable <Amount amount={nft.claimablePiip ?? 0n} displayDecimals={0n} /> PⅡP</div>
        </div>
      </div>
      <div>Claimable rewards:</div>
      <div>
        {nft.rewardTokens && nft.rewardTokens.map((rewardTokenInfo, index) => {
          return <div className='flex-row-valign gap-2' key={index}>
            {/* <div>{tokenAddress.substring(0, 8)}</div> */}
            <div>
              <Amount
                amount={nft.rewardTokens[index].claimableAmount}
                decimals={rewardTokenMetadata?.get?.(rewardTokenInfo.address)?.decimals}
              /> {rewardTokenMetadata?.get?.(rewardTokenInfo.address)?.symbol}
            </div>
          </div>;
        })}
      </div>
    </div>
    <div className='flex flex-col gap-2 items-end'>
      <Web3Button
        className="btn w-36"
        contractAddress={chainConfig.piipAddress}
        contractAbi={piipAbi}
        action={async (ctx: Web3ButtonContext) => {
          console.log("claimReward", [[nft.tokenId]]);
          return ctx.estimateAndSend("claimReward", [[nft.tokenId]]);
        }}
        onError={async (error: unknown) => {
          console.log(error);
          toast.error(`Failed to claim PⅡP: ${(error as Error).message}`);
        }}
        onSuccess={async () => {
          onPiipClaimed();
        }}
      >
        Claim PⅡP
      </Web3Button>

      <Web3Button
        className="btn w-36"
        contractAddress={chainConfig.royaltyPoolAddress}
        contractAbi={royaltyPoolAbi}
        action={async (ctx: Web3ButtonContext) => {
          console.log("claimAllRewards", [nft.tokenId]);
          return ctx.estimateAndSend("claimAllRewards", [nft.tokenId]);
        }}
        onError={async (error: unknown) => {
          console.log(error);
          toast.error(`Failed to claim PⅡP: ${(error as Error).message}`);
        }}
        onSuccess={async () => {
          onPiipClaimed();
        }}
      >
        Claim rewards
      </Web3Button>

      <Web3Button
        className="btn w-36 !bg-red-500"
        contractAddress={chainConfig.hoamiAddress}
        contractAbi={hoamiAbi}
        action={async (ctx: Web3ButtonContext) => {
          console.log("burn", [nft.tokenId]);
          return ctx.estimateAndSend("burn", [nft.tokenId]);
        }}
        onError={async (error: unknown) => {
          console.log(error);
          toast.error(`Failed to mint HOAMI: ${(error as Error).message}`);
        }}
        onSuccess={async () => {
          onBurnt();
        }}
      >
        Burn for HOAMI
      </Web3Button>
    </div>
  </div>;
}

export const Home = () => {
  const account = useAccount();
  console.log("@@@account", {
    address: account.address,
    addresses: account.addresses,
    chain: account.chainId,
    connected: account.isConnected,
    connecting: account.isConnected
  });
  const publicClient = usePublicClient();

  const { chainConfig } = useChainConfig();

  const { data: globalInfo, /*isLoading: globalInfoIsLoading,*/ refetch: globalInfoRefetch } = useReadContracts({
    allowFailure: true,
    contracts: [
      // {
      //   address: chainConfig.hoaAddress,
      //   abi: erc20Abi,
      //   functionName: 'balanceOf',
      //   args: [account.address!],
      // },
      {
        address: chainConfig.hoamiAddress,
        abi: hoamiAbi,
        functionName: 'mintingSunset',
      },
      {
        address: chainConfig.piipAddress,
        abi: piipAbi,
        functionName: 'day',
      },
      {
        address: chainConfig.royaltyPoolAddress,
        abi: royaltyPoolAbi,
        functionName: 'getRewardTokens',
      },
    ],
  });

  const mintingSunset = globalInfo?.[0]?.result;
  const piipDay = globalInfo?.[1]?.result;
  const rewardTokens = globalInfo?.[2]?.result;

  const [rewardTokenMetadataIsFetching, setRewardTokenMetadataIsFetching] = useState(false);
  const [rewardTokenMetadataLoadedRevision, setRewardTokenMetadataLoadedRevision] = useState<readonly `0x${string}`[]>([]);

  const [rewardTokenMetadata, setRewardTokenMetadata] = useState<TokenMetadataMap>(new Map<`0x${string}`, TokenMetadata>());

  useEffect(() => {
    async function fetch(rewardTokens: readonly `0x${string}`[]) {
      if (!publicClient || !rewardTokens)
        return;
      setRewardTokenMetadataIsFetching(true);
      console.log("Fetching reward token metadata");
      try {

        const rewardTokenMetadata = new Map<`0x${string}`, TokenMetadata>();
        {
          const calls = [];
          for (const tokenAddress of rewardTokens) {
            calls.push({
              address: tokenAddress,
              abi: erc20Abi,
              functionName: 'decimals',
            });
            calls.push({
              address: tokenAddress,
              abi: erc20Abi,
              functionName: 'symbol',
            });
          }
          const res = await readContracts(config, {
            contracts: calls,
            allowFailure: true,
          })
          for (let rewardTokenIndex = 0; rewardTokenIndex < rewardTokens.length; ++rewardTokenIndex) {
            const decimals = res[rewardTokenIndex * 2].result as number | undefined;
            const symbol = res[rewardTokenIndex * 2 + 1].result as string | undefined;
            rewardTokenMetadata.set(
              rewardTokens[rewardTokenIndex],
              {
                decimals: decimals ?? 18,
                symbol: symbol ?? "?",
              }
            );
          }

          setRewardTokenMetadata(rewardTokenMetadata);
          console.log("@@@reward token metadata", rewardTokenMetadata);
        }
      }
      finally {
        setRewardTokenMetadataIsFetching(false);
        setRewardTokenMetadataLoadedRevision(rewardTokens);
      }
    }
    if (!rewardTokenMetadataIsFetching && !!rewardTokens && rewardTokenMetadataLoadedRevision !== rewardTokens && !!rewardTokens) {
      fetch(rewardTokens);
    }
  }, [
    chainConfig, publicClient,
    rewardTokenMetadataIsFetching, rewardTokenMetadataLoadedRevision,
    rewardTokens
  ]);

  const [nftListIsFetching, setNftListIsFetching] = useState(false);
  const [nftsListRevision, setNftListRevision] = useState(0);
  const [nftsListLoadedRevision, setNftListLoadedRevision] = useState(-1);
  const [nftList, setNftList] = useState<Array<NftInfo>>([]);

  const [totalClaimablePiip, setTotalClaimablePiip] = useState<bigint>();

  useEffect(() => {
    async function fetch() {
      if (!publicClient || !account || !account.address || !rewardTokens)
        return;
      setNftListIsFetching(true);
      console.log("Fetching NFT list");
      try {
        const nftCount = await publicClient.readContract({
          address: chainConfig.hoamiAddress,
          abi: hoamiAbi,
          functionName: 'balanceOf',
          args: [account.address]
        });

        console.log("nftCount", nftCount);

        if (nftCount > 0n) {
          const nftList: Array<NftInfo> = [];

          { // list of NFTs of current user
            const calls = [];
            for (let nftIndex = 0n; nftIndex < nftCount; nftIndex += 1n) {
              calls.push({
                address: chainConfig.hoamiAddress,
                abi: hoamiAbi,
                functionName: 'tokenOfOwnerByIndex',
                args: [account.address, nftIndex],
              });
            };

            const res = await readContracts(config, {
              contracts: calls,
              allowFailure: true,
            })

            for (const data of res) {
              if (data.result === undefined)
                continue;
              const nftInfo: NftInfo = {
                tokenId: data.result,
                claimablePiip: undefined,
                rewardTokens: [],
              };
              nftList.push(nftInfo);
            }
            nftList.sort((a, b) => Number(a.tokenId - b.tokenId));
          }

          let totalClaimablePiip = 0n;
          { // read info for each NFT
            const calls = [];
            for (const nft of nftList) {
              calls.push({
                address: chainConfig.piipAddress,
                abi: piipAbi,
                functionName: 'claimReward',
                args: [[nft.tokenId]],
              });
              for (const tokenAddress of rewardTokens) {
                calls.push({
                  address: chainConfig.royaltyPoolAddress,
                  abi: royaltyPoolAbi,
                  functionName: 'claimRewards',
                  args: [tokenAddress, [nft.tokenId]],
                });
              }
            };
            const res = await readContracts(config, {
              contracts: calls,
              allowFailure: true,
            })
            const step = rewardTokens.length + 1;
            for (let nftIndex = 0; nftIndex < nftList.length; ++nftIndex) {
              const claimablePiip = res[nftIndex * step + 0].result as bigint | undefined;
              nftList[nftIndex].claimablePiip = claimablePiip;
              if (claimablePiip !== undefined)
                totalClaimablePiip += claimablePiip;
              for (let rewardTokenIndex = 0; rewardTokenIndex < rewardTokens.length; ++rewardTokenIndex) {
                const claimableAmount = res[nftIndex * step + 1 + rewardTokenIndex].result as bigint | undefined;
                nftList[nftIndex].rewardTokens.push({
                  address: rewardTokens[rewardTokenIndex],
                  claimableAmount,
                });
              }
            }
          }
          setTotalClaimablePiip(totalClaimablePiip);

          // console.log("nfts", res);
          setNftList(nftList);
        }
        else {
          setNftList([]);
        }
      }
      finally {
        setNftListIsFetching(false);
        setNftListLoadedRevision(nftsListRevision);
      }
    }
    if (!nftListIsFetching && nftsListLoadedRevision !== nftsListRevision && !!rewardTokens) {
      fetch();
    }
  }, [
    chainConfig, account, publicClient,
    nftsListLoadedRevision, nftsListRevision, nftListIsFetching,
    rewardTokens,
  ]);

  function nftListRefetch() {
    console.log("NFT list update requested");
    setNftListRevision(prev => prev + 1);
  }

  function refresh() {
    globalInfoRefetch();
    nftListRefetch();
  }

  const [mintModalIsOpen, setMintModalIsOpen] = useState(false);

  function mintModalShow() {
    setMintModalIsOpen(true);
  }

  const [mintingSunsetPassed, setMintingSunsetPassed] = useState(false);
  useEffect(() => {
    if (mintingSunsetPassed)
      return;

    const intervalId = setInterval(() => {
      const now = new Date().getTime() / 1000;
      if (mintingSunset !== undefined && Number(mintingSunset) < now) {
        setMintingSunsetPassed(true);
        setMintModalIsOpen(false);
        clearInterval(intervalId);
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [mintingSunset, mintingSunsetPassed]);

  return (
    account?.address ?
      <div className='flex flex-col w-full gap-3'>
        <div className='flex-row-valign flex-wrap gap-2 justify-center'>
          <button
            className='btn w-36'
            onClick={mintModalShow}
            disabled={mintingSunset === undefined || mintingSunsetPassed}
          >
            Mint HOAMI
          </button>
          <button
            className={`btn w-36${nftListIsFetching ? ' animate-pulse' : ''} `}
            onClick={refresh}
          >
            Refresh
          </button>
        </div>
        <div className='flex-row-valign flex-wrap gap-2 justify-center'>
          <Web3Button
            className='btn w-36'
            contractAddress={chainConfig.piipAddress}
            contractAbi={piipAbi}
            action={async (ctx: Web3ButtonContext) => {
              const tokenIds = nftList.map(_ => _.tokenId);
              console.log("claimReward", [tokenIds]);
              return ctx.estimateAndSend("claimReward", [tokenIds]);
            }}
            onError={async (error: unknown) => {
              console.log(error);
              toast.error(`Failed to bulk claim PⅡP: ${(error as Error).message}`);
            }}
            onSuccess={async () => {
              toast.success(`Bulk claiming PⅡP succeeded`);
              globalInfoRefetch();
            }}
            disabled={!(nftList.length > 0)}
          >
           Bulk Claim PⅡP
          </Web3Button>
          {rewardTokens && rewardTokens.map((tokenAddress, index) => {
            const tokenSymbol = rewardTokenMetadata?.get?.(tokenAddress)?.symbol;
            return <Web3Button
              key={index}
              className='btn w-36'
              contractAddress={chainConfig.royaltyPoolAddress}
              contractAbi={royaltyPoolAbi}
              action={async (ctx: Web3ButtonContext) => {
                const tokenIds = nftList.map(_ => _.tokenId);
                console.log("claimRewards", [tokenAddress, tokenIds]);
                return ctx.estimateAndSend("claimRewards", [tokenAddress, tokenIds]);
              }}
              onError={async (error: unknown) => {
                console.log(error);
                toast.error(`Failed to bulk claim ${tokenSymbol} rewards: ${(error as Error).message}`);
              }}
              onSuccess={async () => {
                toast.success(`Bulk claiming ${tokenSymbol} rewards succeeded`);
                globalInfoRefetch();
              }}
              disabled={!(nftList.length > 0)}
            >
              Claim {tokenSymbol}
            </Web3Button>;
          })}
        </div>
        <div className='flex flex-row justify-center gap-2'>
          <div className='flex flex-col items-center panel w-40'>
            <label>Claimable PⅡP</label>
            <div className='value'><Amount amount={totalClaimablePiip} isLoading={nftListIsFetching} displayDecimals={0n} loadingClassName='w-10' /></div>
          </div>
          <div className='flex flex-col items-center panel w-40'>
            <label>PⅡP day</label>
            <div className='value'>{piipDay?.toString?.()}</div>
          </div>
        </div>
        <h2 className='text-center'>
          HOAMI NFT list ({nftList.length})
          :
        </h2>
        {nftListIsFetching &&
          <div className='animate-ping text-center'>
            Loading NFTs...
          </div>
        }
        <div className='grid grid-cols-[repeat(auto-fill,minmax(theme(width.80),1fr))] p-2 gap-3'>
          {nftList.map((nft, index) =>
            <NftPanel
              key={index}
              nft={nft}
              chainConfig={chainConfig}
              rewardTokenMetadata={rewardTokenMetadata}
              onBurnt={nftListRefetch}
              onPiipClaimed={nftListRefetch}
            />
          )}
        </div>

        <MintModal
          isOpen={mintModalIsOpen}
          onClose={() => { setMintModalIsOpen(false); }}
          refresh={refresh}
        />

      </div >
      :
      <div>
        <div>Please connect your wallet first.</div>
        <w3m-button balance='hide' />
      </div>
  );
}

export default Home;
