import { toast } from 'react-toastify';
import { Web3Button } from "./Web3Button";

export const Styles = () => {
  return <div className="relative flex flex-col gap-2 panel">
    <div className="absolute -right-14 top-0">.panel</div>
    <div className="flex flex-wrap gap-2">
      <button className="btn">button.btn</button>
      <button className="btn" disabled>button.btn disabled</button>
      <Web3Button
        contractAddress="0x0"
        contractAbi={[]}
        action={async () => { return undefined; }}
        onError={async () => { }}
        onSuccess={async () => { }}
      >
        Web3Button
      </Web3Button>
    </div>
    <div className="flex flex-wrap gap-2">
      <button className="btn" onClick={() => toast.success("Lorem ipsum dolor sit amet consectetur adipisicing elit")}>Toast success</button>
      <button className="btn" onClick={() => toast.info("Lorem ipsum dolor sit amet consectetur adipisicing elit")}>Toast info</button>
      <button className="btn" onClick={() => toast.error("Lorem ipsum dolor sit amet consectetur adipisicing elit")}>Toast error</button>
    </div>
    <div className="flex-row-valign gap-2">
      <label className="label">label.label:</label>
      <input className="input" value="input.input" onChange={() => { }} />
    </div>
    <div className="flex-row-valign gap-2">
      <label className="label">label.label:</label>
      <span className="value">.value</span>
    </div>
    <div className="h-32 w-96 overflow-auto panel">
      <b>There should be scrollbar at this div.</b>
      {' '}Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat qui consequatur ratione alias sequi harum, amet placeat earum fuga, aliquam nesciunt error quis adipisci laboriosam non veniam ipsa voluptatem culpa?
      {' '}Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat qui consequatur ratione alias sequi harum, amet placeat earum fuga, aliquam nesciunt error quis adipisci laboriosam non veniam ipsa voluptatem culpa?
    </div>
    <div>
      Lorem ipsum dolor sit amet consectetur adipisicing elit.
      <hr />
      Magni provident voluptate harum qui possimus repellat eveniet optio.
    </div>
    {/* <div className="w-fullpanel flex-row-valign gap-2">
      {[
        'Picsart_24-06-28_23-20-03-274.png',
        'Picsart_24-06-28_23-20-46-385.png',
        'Picsart_24-06-28_23-50-06-300.png',
        'Picsart_24-06-28_23-50-16-940.png',
      ].map((pic, index) => <div className="flex flex-col gap-2 items-center">
        <img src={pic} key={index} alt="orange" className="w-32 h-32" />
        <span className="text-[0.6rem]">#{index} {pic}</span>
        <div className='bg-header'>
          <img src={pic} key={index} alt="orange" className="w-32 h-32" />
        </div>
      </div>)}
    </div> */}
  </div>;
}

export default Styles;
