import { toast } from 'react-toastify';
import { useAccount, useReadContracts } from 'wagmi';
import { Web3Button, Web3ButtonContext } from './Web3Button';
import { hoamiAbi, royaltyPoolAbi } from './abis';
import { useChainConfig } from './config';
import { useEffect, useState } from 'react';
import { erc20Abi } from 'viem';
import { Amount } from './Amount';

type RemoveTokenProps = {
  tokenAddress: `0x${string}`;
};

function RemoveToken({ tokenAddress }: RemoveTokenProps) {
  const { chainConfig } = useChainConfig();
  const { data } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'decimals',
      },
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'symbol',
      },
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [chainConfig.royaltyPoolAddress],
      },
    ]
  });

  return <div className='flex-row-valign gap-2'>
    <div>{tokenAddress}</div>
    <div><Amount amount={data?.[2]?.result} decimals={data?.[0]?.result ?? 18} /> {data?.[1]?.result ?? '?'}</div>
    <div className='flex flex-grow justify-end'>
      <Web3Button
        className="btn w-32"
        contractAddress={chainConfig.royaltyPoolAddress}
        contractAbi={royaltyPoolAbi}
        action={async (ctx: Web3ButtonContext) => {
          console.log("removeRewardToken", [tokenAddress]);
          return ctx.estimateAndSend("removeRewardToken", [tokenAddress]);
        }}
        onError={async (error: unknown) => {
          console.log(error);
          toast.error(`Failed change minting sunset: ${(error as Error).message}`);
        }}
        onSuccess={async () => {
          toast.success(`Minting sunset changed`);
        }}
      >
        Remove
      </Web3Button>
    </div>
  </div>;
}

function AdministerTokenAddresses() {
  const { chainConfig } = useChainConfig();
  const [tokenAddress, setTokenAddress] = useState("");

  const { data: globalInfo } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        address: chainConfig.royaltyPoolAddress,
        abi: royaltyPoolAbi,
        functionName: 'getRewardTokens',
      },
    ],
  });

  const rewardTokenAddresses = globalInfo?.[0]?.result;

  return <div className='panel flex flex-col gap-2'>
    <div className='title-xl'>Royalty Pool Management</div>
    <div className='flex-row-valign gap-2 flex-wrap'>
      <div className="flex-row-valign flex-wrap">
        <label>ERC20 token address:</label>
        <input
          type='string'
          className="input w-[26rem]"
          value={tokenAddress}
          onChange={ev => setTokenAddress(ev.target.value)}
        />
      </div>
      <Web3Button
        className="btn w-32"
        contractAddress={chainConfig.royaltyPoolAddress}
        contractAbi={royaltyPoolAbi}
        action={async (ctx: Web3ButtonContext) => {
          console.log("addRewardToken", [tokenAddress]);
          return ctx.estimateAndSend("addRewardToken", [tokenAddress]);
        }}
        onError={async (error: unknown) => {
          console.log(error);
          toast.error(`Failed change minting sunset: ${(error as Error).message}`);
        }}
        onSuccess={async () => {
          toast.success(`Minting sunset changed`);
        }}
      >
        Add token
      </Web3Button>
    </div>
    <div className='flex flex-col gap-2'>
      {rewardTokenAddresses && rewardTokenAddresses.map((addr, index) =>
        <RemoveToken key={index} tokenAddress={addr} />
      )}
    </div>
  </div>;
}

function AdministerMintingSunset() {
  const { chainConfig } = useChainConfig();

  const { data: globalInfo } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        address: chainConfig.hoamiAddress,
        abi: hoamiAbi,
        functionName: 'mintingSunset',
      },
    ],
  });

  const mintingSunset = globalInfo?.[0]?.result;
  const [mintingSunsetValue, setMintingSunsetValue] = useState<number>();

  useEffect(() => {
    if (mintingSunset !== undefined && mintingSunsetValue === undefined) {
      setMintingSunsetValue(Number(mintingSunset));
    }
  }, [mintingSunset, mintingSunsetValue]);

  function formatDateToDatetimeLocal(date: Date) {
    const pad = (num: Number) => String(num).padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function parseDatetimeLocal(value: string) {
    const [datePart, timePart] = value.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);
    const date = new Date(year, month - 1, day, hours, minutes);
    return date;
  }

  return <div className='panel flex flex-col'>
    <div className='title-xl'>Minting Sunset Management [Testnet only]</div>
    <div className='flex-row-valign gap-2 flex-wrap'>
      <div className="flex-row-valign flex-wrap">
        <label>Minting sunset:</label>
        <input
          type='datetime-local'
          className="input text-right w-64"
          value={formatDateToDatetimeLocal(mintingSunsetValue ? new Date(mintingSunsetValue * 1000) : new Date())}
          onChange={ev => {
            const date = parseDatetimeLocal(ev.target.value);
            setMintingSunsetValue(Math.round(date.getTime() / 1000));
          }}
        />
      </div>
      <Web3Button
        className="btn w-32"
        contractAddress={chainConfig.hoamiAddress}
        contractAbi={hoamiAbi}
        action={async (ctx: Web3ButtonContext) => {
          console.log("setMintingSunset", [mintingSunsetValue]);
          return ctx.estimateAndSend("setMintingSunset", [mintingSunsetValue]);
        }}
        onError={async (error: unknown) => {
          console.log(error);
          toast.error(`Failed change minting sunset: ${(error as Error).message}`);
        }}
        onSuccess={async () => {
          toast.success(`Minting sunset changed`);
        }}
      >
        Change
      </Web3Button>
      <div className="flex-row-valign flex-wrap">
        <button className="btn w-32" onClick={() => setMintingSunsetValue(Math.round(new Date().getTime() / 1000) - 86400)}>-1d</button>
        <button className="btn w-32" onClick={() => setMintingSunsetValue(Math.round(new Date().getTime() / 1000) - 3600)}>-1h</button>
        <button className="btn w-32" onClick={() => setMintingSunsetValue(Math.round(new Date().getTime() / 1000) + 3600)}>+1h</button>
      </div>
    </div>
  </div>;
}

export const Administration = () => {
  const account = useAccount();

  return (
    account?.address ?
      <div className='flex flex-col gap-3'>
        <AdministerMintingSunset />
        <AdministerTokenAddresses />
      </div >
      :
      <div>
        <div>Please connect your wallet first.</div>
        <w3m-button balance='hide' />
      </div>
  );
}

export default Administration;
