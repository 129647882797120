import { formatUnits } from "viem";

export type AmountProps = {
  amount: bigint | undefined;
  decimals?: number | bigint;
  displayDecimals?: bigint;
  isLoading?: boolean;
  loadingClassName?: string;
}

export function Amount({ amount, displayDecimals = 4n, decimals = 18n, isLoading, loadingClassName }: AmountProps) {
  const decimalsN = Number(decimals);
  const decimalsBI = BigInt(decimals);
  if (typeof amount == "bigint") {
    const precision = 10n ** (decimalsBI - displayDecimals);
    amount = amount / precision * precision;
  }
  return isLoading ?
    <div className={`inline-block animate-pulse bg-slate-500${loadingClassName ? ` ${loadingClassName}` : ""}`}>&nbsp;&nbsp;&nbsp;&nbsp;</div>
    :
    <>
      {typeof amount == "bigint" ? formatUnits(amount, decimalsN) : "N/A"}
    </>;
}

