import Modal from 'react-modal';
import { useAccount, useReadContracts } from "wagmi";
import { useChainConfig } from "./config";
import { useState } from "react";
import { erc20Abi } from "viem";
import { hoamiAbi } from "./abis";
import { Amount } from './Amount';
import { Web3Button, Web3ButtonContext } from './Web3Button';
import { toast } from 'react-toastify';

type MintModalProps = {
    isOpen: boolean;
    onClose: () => void;
    refresh: () => void;
};

export function MintModal({ isOpen, onClose, refresh }: MintModalProps) {
    const account = useAccount();
    const { chainConfig } = useChainConfig();
    const [amount, setAmount] = useState(1);

    const { data, isLoading, refetch } = useReadContracts({
        allowFailure: true,
        contracts: [
            {
                address: chainConfig.hoaAddress,
                abi: erc20Abi,
                functionName: 'allowance',
                args: [account.address!, chainConfig.hoamiAddress],
            },
            {
                address: chainConfig.hoamiAddress,
                abi: hoamiAbi,
                functionName: 'mintingFee',
            },
        ],
    });

    const allowance = (data?.[0]?.result as bigint | undefined) ?? 0n;
    const mintingFee = data?.[1]?.result as bigint | undefined;
    const mintingFeeTotal = mintingFee !== undefined ? mintingFee * BigInt(amount ?? 0) : 0n;
    const allowanceSufficient = allowance >= mintingFeeTotal;

    // (document.getElementById('mintModalAmount') as HTMLInputElement).focus();

    return <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={{
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0)"
            },
            content: {
                width: "20rem",
                transform: "translate(0%, 50%)",
            }
        }}
        className="popup modal panel"
    >
        <h3 className="font-bold text-lg">Mint HOAMI</h3>
        <div className="mt-4 flex-row-valign">
            <label>Amount to mint:</label>
            <input
                id="mintModalAmount"
                min={1}
                type='number'
                className="input text-right w-20"
                value={amount}
                onChange={ev => setAmount(parseInt(ev.target.value || "0"))}
            />
        </div>
        <div className="mt-4 flex-row-valign">
            <label>Minting price:</label>
            <span className='value'><Amount amount={mintingFee} isLoading={isLoading} loadingClassName='w-16' /> HOA</span>
        </div>
        <div className="mt-4 flex-row-valign">
            <label>Required funds:</label>
            <span className='value'><Amount amount={mintingFeeTotal} isLoading={isLoading} loadingClassName='w-16' /> HOA</span>
        </div>
        <div className="mt-4 flex-row-valign justify-center">
            {/* if there is a button in form, it will close the modal */}
            <div className="mt-4 flex flex-row gap-4">
                {!allowanceSufficient &&
                    <Web3Button
                        className="btn w-32"
                        contractAddress={chainConfig.hoaAddress}
                        contractAbi={erc20Abi}
                        disabled={!(amount > 0)}
                        action={async (ctx: Web3ButtonContext) => {
                            console.log("approve", [chainConfig.hoamiAddress, mintingFeeTotal]);
                            return ctx.estimateAndSend("approve", [chainConfig.hoamiAddress, mintingFeeTotal]);
                        }}
                        onError={async (error: unknown) => {
                            console.log(error);
                            toast.error(`Failed to mint HOAMI: ${(error as Error).message}`);
                        }}
                        onSuccess={async () => {
                            refetch();
                        }}
                    >
                        Approve
                    </Web3Button>
                }
                {allowanceSufficient &&
                    <Web3Button
                        className="btn w-32"
                        contractAddress={chainConfig.hoamiAddress}
                        contractAbi={hoamiAbi}
                        disabled={!(amount > 0)}
                        action={async (ctx: Web3ButtonContext) => {
                            console.log("mint", [amount]);
                            return ctx.estimateAndSend("mint", [amount]);
                        }}
                        onError={async (error: unknown) => {
                            console.log(error);
                            toast.error(`Failed to mint HOAMI: ${(error as Error).message}`);
                        }}
                        onSuccess={async () => {
                            onClose();
                            refresh();
                        }}
                    >
                        Mint
                    </Web3Button>
                }
                <button className="btn h-11 w-32" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    </Modal>;
}

